<template>
    <LayoutTextConverter :slug="slug" input-height="200" output-height="160">

    <template #options>
        <div class="input-group max-w-[200px] mx-auto">
            <label>Rounds (1 ~ 20)</label>
            <input type="number" v-model="store.data[slug].options.rounds" class="text-field" min="1" max="20">
        </div>
    </template>
    </LayoutTextConverter>
</template>

<script setup lang="ts">
import LayoutTextConverter from "&/LayoutTextConverter.vue"

import { useRoute } from "vue-router";
import { useStore } from '%/stores'

const route = useRoute()
const store = useStore()
const slug = route.path.replace('/', '')
</script>
