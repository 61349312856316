<template>
  <LayoutCodeConverter :slug="slug" input-lang="css" output-lang="css">
      <template #options>
          <div class="input-group">
              <label>Line length</label>
              <input type="number" v-model="store.data[slug].options.length" min="1" class="text-field">
          </div>
      </template>
  </LayoutCodeConverter>
</template>

<script setup lang="ts">
import { useFeatureSetup } from '&/composables/featureSetup'
const { route, store, slug, LayoutCodeConverter } = useFeatureSetup()
</script>
