<template>
    <LayoutTextConverter :slug="slug" input-height="150" output-height="150"/>
</template>

<script setup lang="ts">
import { useFeatureSetup } from '&/composables/featureSetup'
import LayoutTextConverter from "&/LayoutTextConverter.vue";

const { slug } = useFeatureSetup()
</script>
