<template>
  <div v-if="!store.dataLoading" class="">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'

const store = useStore()
</script>
