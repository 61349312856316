<template>
    <LayoutTextConverter :slug="slug" input-height="200" output-height="120">
    </LayoutTextConverter>
</template>

<script setup lang="ts">
import LayoutTextConverter from "&/LayoutTextConverter.vue"

import { useRoute } from "vue-router";
import { useStore } from '%/stores'

const route = useRoute()
const store = useStore()
const slug = route.path.replace('/', '')
</script>
