<template>
  <div v-if="ready">
    <FeatureSchema :feature="store.feature" />

    <div class="bg-stone-50">
      <main class="max-w-[1600px] mx-auto p-4 pb-12 programming-editor">
        <AppBreadcrumbs :breadcrumbs="breadcrumbs" />

        <h1 class="page-title">Online {{ store.feature.name }}</h1>
        <div class="page-subtitle">{{ store.feature.title }}</div>

        <AdSenseTop class="my-4" />

        <section>
          <div class="input-group flex space-x-6 items-center justify-center">
            <label
              v-for="theme in [
                ['Light', 'light'],
                ['Dark', 'dark']
              ]"
              :key="theme"
            >
              <input v-model="store.data['programming-editors'].theme" type="radio" :value="theme[1]" />
              <span>{{ theme[0] }} theme</span>
            </label>
          </div>
        </section>

        <iframe
          class="my-4 shadow-lg"
          frameBorder="0"
          height="600px"
          :src="`https://onecompiler.com/embed/${store.feature.action_text}?theme=${store.data['programming-editors'].theme}&availableLanguages=${store.systemConfigs.available_editor_languages}&disableCopyPaste=false&hideTitle=true`"
          width="100%"
        />

        <AdSenseBottom class="my-4" />

        <div class="grid grid-cols-12 gap-8 mt-8">
          <div class="col-span-12 md:col-span-8">
            <FeatureInstruction class="" :feature="store.feature" />
          </div>

          <div class="col-span-12 md:col-span-4">
            <AdSenseAside />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useHead } from '@vueuse/head'
import AdSenseTop from '&/adsense/AdSenseTop.vue'
import AdSenseBottom from '&/adsense/AdSenseBottom.vue'

import { useFeatureSetup } from '&/composables/featureSetup'
const { store } = useFeatureSetup()

const props = defineProps<{
  slug: string
}>()

import FeatureInstruction from '&/FeatureInstruction.vue'
import FeatureSchema from '&/FeatureSchema.vue'
import AdSenseAside from '&/adsense/AdSenseAside.vue'

const breadcrumbs = ref([])
const ready = ref(false)

onMounted(() => {
  store
    .fetchFeature(props.slug)
    .then(() => {
      useHead({
        title: store.feature.title,
        meta: [
          {
            name: 'description',
            content: store.feature.description
          },
          {
            name: 'keywords',
            content: store.feature.keywords
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: `https://toolpro.dev/${store.feature.slug}`
          },
        ],
      })
      breadcrumbs.value = [
        { path: `/categories/${store.feature.category.slug}`, name: `${store.feature.category.name}` },
        { path: null, name: `Online ${store.feature.name}` }
      ]
    })
    .finally(() => (ready.value = true))
})
</script>
