<template>
  <div class="bg-stone-800">
    <div class="max-w-[1800px] mx-auto pt-12">
      <h3 class="text-center text-3xl font-bold text-white">Online Code Formatter & Editor</h3>
      <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6 p-8">
        <div v-for="category in store.categories" :key="category.slug" class="">
          <div class="font-bold text-xl mb-4 text-white">{{ category.name }}</div>
          <ul class="flex flex-col space-y-2 text-gray-200">
            <li v-for="feature in category.features.slice(0, 12)" :key="feature.slug">
              <router-link class="link" :to="{ path: `/${feature.slug}` }">
                {{ feature.name }}
              </router-link>
            </li>
            <li v-if="category.features.length > 12">
              <router-link class="underline link hover:italic" :to="{ path: `/categories/${category.slug}` }">
                See more...
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'

const store = useStore()
</script>
