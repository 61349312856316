<template>
  <div v-if="ready">
    <FeatureSchema :feature="store.feature" />

    <div class="bg-stone-50">
      <main class="max-w-[1800px] mx-auto p-4 pb-12 text-converter">
        <AppBreadcrumbs :breadcrumbs="breadcrumbs" />

        <AdSenseTop class="my-4" />

        <div class="max-w-screen-xl mx-auto">
          <div class="grid grid-cols-12 gap-6">
            <TheAside class="col-span-12 lg:col-span-4 lg:order-last">
              <AdSenseAside />

              <div class="grid grid-cols-2 gap-4 mt-4">
                <router-link
                  v-for="relatedFeature in store.feature.related_features"
                  :key="relatedFeature.slug"
                  class="button button--outline col-span-1 lg:col-span-2"
                  :to="{ path: relatedFeature.slug }"
                >
                  {{ relatedFeature.name }}
                </router-link>
              </div>
            </TheAside>

            <main class="p-4 col-span-12 lg:col-span-8 bg-white shadow-lg lg:order-first">
              <h1 class="page-title">{{ store.feature.name }}</h1>
              <div class="page-subtitle">{{ store.feature.title }}</div>

              <div class="flex space-y-4 flex-col mt-4">
                <section class="w-full">
                  <slot name="input">
                    <div :style="`height: ${inputHeight}px;`">
                      <AppTextInput
                        v-model="store.data[slug].input"
                        :placeholder="store.feature.sample_placeholder"
                        :lang="inputLang"
                        :height="inputHeight"
                        @get-sample="store.executeSample(slug)"
                      />
                    </div>
                  </slot>
                </section>

                <section class="mt-4">
                  <div class="empty:hidden mb-4">
                    <slot name="options"></slot>
                  </div>
                  <slot name="submit">
                    <div class="flex justify-center">
                      <button
                        name="submit"
                        class="button button--primary button--large"
                        :disabled="store.dataSending"
                        @click="store.execute(slug)"
                      >
                        <span v-if="store.dataSending">
                          <i class="animate-spin fa-solid fa-spinner" />
                        </span>
                        <span v-else>
                          <i class="fa-solid fa-bolt-lightning"></i>
                        </span>
                        <span class="ml-1">{{ store.feature.action_text }}</span>
                      </button>
                    </div>
                  </slot>
                </section>

                <section class="w-full">
                  <slot name="output">
                    <div :style="`height: ${outputHeight}px;`">
                      <AppTextInput
                        v-model="store.data[slug].output"
                        editor-id="ace-output"
                        :for-output="true"
                        :lang="outputLang"
                        :height="outputHeight"
                        :action-text="store.feature.action_text"
                      />
                    </div>
                  </slot>
                </section>

                <AdSenseBottom class="mt-6" />
              </div>
            </main>
          </div>
        </div>

        <div class="grid grid-cols-12 gap-8 mt-8">
          <div class="col-span-12 md:col-span-8">
            <FeatureInstruction class="" :feature="store.feature" />
          </div>

          <div class="col-span-12 md:col-span-4">
            <AdSenseAside />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useStore } from '%/stores'
import { useHead } from '@vueuse/head'

import TheAside from '&/TheAside.vue'
import AppTextInput from '&/AppTextInput.vue'
import FeatureInstruction from '&/FeatureInstruction.vue'
import AdSenseTop from '&/adsense/AdSenseTop.vue'
import AdSenseBottom from '&/adsense/AdSenseBottom.vue'
import AdSenseAside from '&/adsense/AdSenseAside.vue'
import FeatureSchema from '&/FeatureSchema.vue'

const props = withDefaults(
  defineProps<{
    slug: string
    inputLang?: string
    outputLang?: string
    inputHeight?: string
    outputHeight?: string
  }>(),
  {
    inputLang: 'text',
    outputLang: 'text',
    inputHeight: '240',
    outputHeight: '240'
  }
)

const store = useStore()
const breadcrumbs = ref([])
const ready = ref(false)

onMounted(() => {
  store
    .fetchFeature(props.slug)
    .then(() => {
      useHead({
        title: store.feature.title,
        meta: [
          {
            name: 'description',
            content: store.feature.description
          },
          {
            name: 'keywords',
            content: store.feature.keywords
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: `https://toolpro.dev/${store.feature.slug}`
          },
        ],
      })
      breadcrumbs.value = [
        { path: `/categories/${store.feature.category.slug}`, name: `${store.feature.category.name}` },
        { path: null, name: store.feature.name }
      ]
    })
    .finally(() => (ready.value = true))
})
</script>
