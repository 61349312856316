<template>
  <AppAdSense
    ad-class="adsense adsense--bottom"
    ad-style="display:block"
    ad-client="ca-pub-9118328092882671"
    ad-slot="9421907833"
    ad-format="autorelaxed"
  />
</template>

<style lang="sass" scoped>
.adsense--bottom
  min-height: 50px
</style>
