<template>
  <div id="map" class="h-full"></div>
</template>

<script setup lang="ts">
import { Loader } from '@googlemaps/js-api-loader'

const props = defineProps<{
  lat: number
  lng: number
}>()

const position = { lat: props.lat, lng: props.lng }

const loader = new Loader({
  apiKey: 'AIzaSyDLn_ssBsS4IO9s7-8Off4hrxVafbINSss',
  version: 'weekly'
})

loader.load().then(async () => {
  // eslint-disable-next-line
  const { Map } = await google.maps.importLibrary('maps')
  // eslint-disable-next-line
  const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary('marker')

  const map = new Map(document.getElementById('map'), {
    mapId: 'MAP_ID',
    center: position,
    zoom: 7
  })

  // eslint-disable-next-line
  new google.maps.marker.AdvancedMarkerElement({
    position,
    map
  })
})
</script>
