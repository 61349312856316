<template>
  <div>
    <CategorySchema :category="category" />

    <div class="max-w-screen-2xl mx-auto">
      <main class="bg-white p-4 pb-12">
        <AppBreadcrumbs :breadcrumbs="breadcrumbs" />

        <h1 class="page-title">{{ category.name }}</h1>
        <div class="text-xl">{{ category.description }}</div>

        <div class="main-menu mt-12">
          <FeatureList :features="category.features"></FeatureList>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '%/stores'
import FeatureList from '&/FeatureList.vue'
import { useHead } from '@vueuse/head'
import _ from 'lodash'
import CategorySchema from '&/CategorySchema.vue'

const route = useRoute()
const store = useStore()
const breadcrumbs = ref([])

const category = reactive(store.getCategoryBySlug(_.split(route.path, '/categories/')[1]))

useHead({
  title: category.title,
  meta: [
    {
      name: 'description',
      content: category.description
    }
  ]
})
breadcrumbs.value = [{ path: null, name: category.name }]
</script>
