<template>
  <AppAdSense
    ad-class="adsense adsense--square"
    ad-style="display:block"
    ad-client="ca-pub-9118328092882671"
    ad-slot="5624766076"
    ad-responsive="true"
  />
</template>

<style lang="sass" scoped>
.adsense--square
  min-height: 50px
  height: 300px !important
</style>
