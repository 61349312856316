<template>
    <LayoutCodeConverter :slug="slug">
        <template #action>
            <div>
                <button v-if="store.data[slug].input" @click="download" class="button button--primary button--large w-full">
                    <i class="fa-solid fa-file-arrow-down"></i>
                    <span>Download image</span>
                </button>
            </div>
        </template>
        <template #output>
            <div class="w-full h-full">
                <div class="flex justify-center mt-12">
                    <img v-show="store.data[slug].input" id="outputImage" alt="Base64 to Image" class="" />
                </div>
            </div>
        </template>
    </LayoutCodeConverter>
</template>

<script setup lang="ts">
import { watch, onMounted } from "vue";
import LayoutCodeConverter from "&/LayoutCodeConverter.vue"

import { useStore } from '%/stores'
import { useRoute } from "vue-router";
const route = useRoute()
const store = useStore()
const slug = route.path.replace('/', '')

const download = function() {
    var img = document.getElementById('outputImage')
    const link = document.createElement('a');
    link.href = img.src;
    link.download = 'toolpro.dev.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const getImage = function() {
    if (store.data[slug].input) {
        var img = document.getElementById('outputImage')
        if (img) img.src = "data:image/jpeg;base64," + store.data[slug].input;
    }
}

onMounted(() => getImage())

watch(() => store.data[slug].input,
    () => getImage(),
    { immediate: true } // call the watcher immediately during setup, not waiting for the value to change
)
</script>
