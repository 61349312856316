<template>
  <header>
    <nav class="bg-gradient-to-b from-zinc-200 to-stone-100 shadow-md px-2 py-0 w-full">
      <div class="max-w-screen-2xl mx-auto flex flex-wrap items-center justify-between">
        <router-link :to="{ name: 'CategoryIndex' }" class="navbar-brand flex items-center space-x-1 text-2xl">
          <img src="@/images/logo_128.png" alt="toolpro.dev logo" class="w-16" />
          <span>ToolPro.dev</span>
        </router-link>

        <button
          data-collapse-toggle="navbar-dropdown"
          type="button"
          class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-dropdown"
          aria-expanded="false"
        >
          <span class="sr-only">Open main menu</span>
          <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>

        <div class="hidden w-full lg:block lg:w-auto" id="navbar-dropdown">
          <ul
            class="flex flex-col font-medium space-y-4 lg:space-y-0 p-4 lg:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 lg:space-x-8 rtl:space-x-reverse lg:flex-row lg:mt-0 lg:border-0 lg:bg-transparent dark:bg-gray-800 lg:dark:bg-gray-900 dark:border-gray-700"
          >
            <li>
              <router-link :to="{ name: 'CategoryIndex' }" class="">
                <i class="fa-solid fa-house"></i>
                <span>Home</span>
              </router-link>
            </li>

            <li class="">
              <router-link :to="{ name: 'XmlFormatter' }" class=""> XML Formatter </router-link>
            </li>
            <li class="">
              <router-link :to="{ name: 'JsBeautifier' }" class=""> JS Beautifier </router-link>
            </li>

            <li class="">
              <button id="dropdownCSSLink" data-dropdown-toggle="dropdownCSS" class="flex items-center justify-between">
                CSS Converters
                <svg
                  class="w-2.5 h-2.5 ms-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <div
                id="dropdownCSS"
                class="hidden z-10 font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
              >
                <ul class="py-2 text-base text-gray-700 dark:text-gray-400" aria-labelledby="dropdownLargeButton">
                  <li v-for="feature in store.getCategoryBySlug('css-converters')?.features" :key="feature.slug">
                    <router-link :to="`/${feature.slug}`" class="dropdown-item">
                      {{ feature.name }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>

            <li class="">
              <button
                id="dropdownEditorsLink"
                data-dropdown-toggle="dropdownEditors"
                class="flex items-center justify-between"
              >
                Online Editors
                <svg
                  class="w-2.5 h-2.5 ms-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <div
                id="dropdownEditors"
                class="hidden z-10 w-full max-w-[800px] font-normal bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
              >
                <ul
                  class="grid grid-cols-2 lg:grid-cols-4 py-2 text-base text-gray-700 dark:text-gray-400"
                  aria-labelledby="dropdownLargeButton"
                >
                  <li v-for="feature in store.getCategoryBySlug('programming-editors')?.features" :key="feature.slug">
                    <router-link :to="`/${feature.slug}`" class="dropdown-item">
                      {{ feature.name }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'
import { useFlowbite } from '&/composables/useFlowbite'

const store = useStore()

useFlowbite(['dropdowns'])
</script>
