<template>
    <LayoutTextConverter :slug="slug" input-height="200" output-height="200">
        <template #options>
            <div class="flex space-x-4 justify-center">
                <div class="input-group">
                    <label>
                        <input type="radio" v-model="store.data[slug].options.reverse_by" value="letter" @change="store.execute(slug)"/>
                        <span>By letter</span>
                    </label>
                </div>
                <div class="input-group">
                    <label>
                        <input type="radio" v-model="store.data[slug].options.reverse_by" value="word" @change="store.execute(slug)"/>
                        <span>By word</span>
                    </label>
                </div>
            </div>
        </template>
    </LayoutTextConverter>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'
import LayoutTextConverter from "&/LayoutTextConverter.vue"

const store = useStore()
import { useRoute } from "vue-router";
const route = useRoute()
const slug = route.path.replace('/', '')
</script>
