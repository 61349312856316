<template>
  <LayoutCodeConverter :slug="slug" input-lang="css" output-lang="css">
      <template #options>
          <OptionIndent :slug="slug"/>

          <div class="input-group mt-4">
              <label>
                  <input type="checkbox" v-model="store.data[slug].options.open_brace_end_of_line" @change="store.execute(slug)" >
                  <span>Open brace end of line</span>
              </label>
          </div>

          <div class="input-group mt-4">
              <label>
                  <input type="checkbox" v-model="store.data[slug].options.auto_semicolon" @change="store.execute(slug)">
                  <span>Auto semicolon</span>
              </label>
          </div>
      </template>
  </LayoutCodeConverter>
</template>

<script setup lang="ts">
import OptionIndent from "&/OptionIndent.vue";

import { useFeatureSetup } from '&/composables/featureSetup'
const { route, store, slug, LayoutCodeConverter } = useFeatureSetup()
</script>
