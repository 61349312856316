<template>
  <LayoutCodeConverter :slug="slug" input-lang="json" output-lang="json">
      <template #options>
          <OptionIndent :slug="slug"/>
      </template>
  </LayoutCodeConverter>
</template>

<script setup lang="ts">
import LayoutCodeConverter from "&/LayoutCodeConverter.vue"
import OptionIndent from "&/OptionIndent.vue";

import { useRoute } from "vue-router";
const route = useRoute()
const slug = route.path.replace('/', '')
</script>
