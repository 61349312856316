<template>
  <div v-if="store.ready">
    <FeatureSchema :feature="store.feature" />

    <div class="bg-stone-50">
      <main class="max-w-[1800px] mx-auto p-4 pb-12">
        <AppBreadcrumbs :breadcrumbs="breadcrumbs" />

        <h1 class="page-title">{{ store.feature.name }}</h1>
        <div class="page-subtitle">{{ store.feature.title }}</div>

        <AdSenseTop />

        <div class="my-6">
          <slot />
        </div>

        <AdSenseBottom class="mt-6" />

        <div class="grid grid-cols-12 gap-8 mt-8">
          <div class="col-span-12 md:col-span-8">
            <FeatureInstruction class="" :feature="store.feature" />
          </div>

          <div class="col-span-12 md:col-span-4">
            <AdSenseAside />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useStore } from '%/stores'
import { useHead } from '@vueuse/head'

import FeatureSchema from '&/FeatureSchema.vue'
import FeatureInstruction from '&/FeatureInstruction.vue'
import AdSenseTop from '&/adsense/AdSenseTop.vue'
import AdSenseBottom from '&/adsense/AdSenseBottom.vue'
import AdSenseAside from '&/adsense/AdSenseAside.vue'

const props = withDefaults(
  defineProps<{
    slug: string
  }>(),
  {}
)

const store = useStore()
const breadcrumbs = ref([])

onMounted(() => {
  store
    .fetchFeature(props.slug)
    .then(() => {
      useHead({
        title: store.feature.title,
        meta: [
          {
            name: 'description',
            content: store.feature.description
          },
          {
            name: 'keywords',
            content: store.feature.keywords
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: `https://toolpro.dev/${store.feature.slug}`
          },
        ],
      })
      breadcrumbs.value = [
        { path: `/categories/${store.feature.category.slug}`, name: `${store.feature.category.name}` },
        { path: null, name: store.feature.name }
      ]
    })
    .finally(() => (store.ready = true))
})
</script>
