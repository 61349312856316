<template>
  <ins
    class="adsbygoogle"
    data-ad-client="ca-pub-9118328092882671"
    :data-ad-layout="adLayout"
    :data-ad-slot="adSlot"
    :data-ad-format="adFormat"
    :data-ad-layout-key="adLayoutKey"
    :data-full-width-responsive="adResponsive"
    :style="adStyle"
    :class="adClass"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  props: {
    adLayout: {
      type: String,
      default: ''
    },
    adSlot: {
      type: String,
      default: ''
    },
    adFormat: {
      type: String,
      default: ''
    },
    adLayoutKey: {
      type: String,
      default: ''
    },
    adResponsive: {
      type: String,
      default: ''
    },
    adStyle: {
      type: String,
      default: ''
    },
    adClass: {
      type: String,
      default: ''
    }
  },

  setup() {
    onMounted(() => {
      ;(window.adsbygoogle = window.adsbygoogle || []).push({})
    })
  }
})
</script>
