<template>
  <LayoutPlain :slug="slug">
    <textarea id="markdown-input"/>
  </LayoutPlain>
</template>

<script setup lang="ts">
import { useFeatureSetup } from '&/composables/featureSetup'
const { route, store, slug, LayoutPlain } = useFeatureSetup()
import { onMounted } from "vue";
import EasyMDE from "easymde";
import 'easymde/dist/easymde.min.css'

onMounted(() => {
  const checkElement = setInterval(() => {
    const element = document.getElementById('markdown-input');
    if (element) {
      clearInterval(checkElement);
      initMarkdown();
    }
  }, 100);
});

const initMarkdown = function() {
  const easyMDE = new EasyMDE({
    element: document.getElementById('markdown-input'),
    placeholder: "Let's write something cool!",
    autofocus: true,
    spellChecker: false,
    sideBySideFullscreen: false,
    lineNumbers: false,
    unorderedListStyle: '-',
    previewClass: 'markdown-body',
    toolbar: [
      'heading-2', 'heading-3', '|',
      'bold', 'italic', 'strikethrough', '|',
      'unordered-list', 'ordered-list', '|',
      'quote', 'code', 'table', 'horizontal-rule', 'link', 'image', '|',
      'side-by-side', 'preview', 'fullscreen', '|',
      'undo', 'redo', 'clean-block', '|',
      'guide'
    ],
    renderingConfig: {
      codeSyntaxHighlighting: true,
      hljs: window.hljs,
    },
  });
  easyMDE.toggleSideBySide()
  console.log('Initialized markdown editor.')
}
</script>
