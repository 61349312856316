<template>
  <LayoutCodeConverter :slug="slug" input-lang="scss" output-lang="css">
      <template #options>
        <OptionIndent :slug="slug"/>

        <div class="input-group mt-4">
            <label>Style</label>
            <select v-model="store.data[slug].options.style" class="text-field" @change="store.execute(slug)">
                <option v-for="style in styles" :key="style" :value="style">{{ style }}</option>
            </select>
        </div>
      </template>
  </LayoutCodeConverter>
</template>

<script setup lang="ts">
import OptionIndent from "&/OptionIndent.vue";

import { useFeatureSetup } from '&/composables/featureSetup'
const { route, store, slug, LayoutCodeConverter } = useFeatureSetup()

const styles = [
    'expanded',
    'nested',
    'compact',
    'compressed'
]
</script>
