<template>
  <div class="bg-stone-100 p-4">
    <AppBreadcrumbs :breadcrumbs="breadcrumbs" />

    <div class="mx-auto max-w-xl">
      <form class="flex flex-col space-y-4" @submit.prevent="onSubmit">
        <ul v-if="errors.length > 0" id="errors_explanation">
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>

        <div>
          <label>Name</label>
          <input v-model="formData.name" type="text" class="text-field" />
        </div>
        <div>
          <label>Email</label>
          <input v-model="formData.email" type="email" class="text-field" />
        </div>
        <div>
          <label>Subject</label>
          <select v-model="formData.contact_subject_id" class="text-field">
            <option v-for="subject in subjects" :key="subject[1]" :value="subject[1]">
              {{ subject[0] }}
            </option>
          </select>
        </div>
        <div>
          <label>Message</label>
          <textarea v-model="formData.message" rows="10" class="text-field" />
        </div>
        <div class="flex justify-center">
          <button type="submit" class="button button--primary button--large">Send feedback</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useNotify } from '&/composables/useNotify'
import apiCall from '%/plugins/axios'

useHead({
  title: 'Contact toolpro.dev'
})

const router = useRouter()
const { pushNotify } = useNotify()
const formData = reactive({
  category_subject_id: null,
  name: '',
  email: '',
  message: ''
})
const errors = ref([])

const subjects = [
  ['Bug Report', 1],
  ['Feature Request', 2],
  ['Advertisement', 7],
  ['Technical Support', 3],
  ['General Inquiry', 4],
  ['Feedback', 5],
  ['Other', 6]
]

const breadcrumbs = ref([])

const onSubmit = function () {
  apiCall
    .post('/contacts', { contact: formData })
    .then(() => {
      router.push({ name: 'CategoryIndex' })
      setTimeout(() => {
        pushNotify('Your feedback means a lot to me!', 'Thank for your feedback')
      }, 500)
    })
    .catch((err) => (errors.value = err.response.data.errors))
}

breadcrumbs.value = [{ path: null, name: `Contact` }]
</script>
