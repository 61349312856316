<template>
  <div class="shadow">
    <h4 class="bg-gradient-to-b from-green-300 to-cyan-400 font-bold px-4 py-2 text-lg rounded-t-lg">
      <slot name="header"> </slot>
    </h4>

    <div class="border border-stone-300 p-2 bg-white">
      <slot name="body"></slot>
    </div>
  </div>
</template>
