<template>
    <LayoutTextConverter :slug="slug">
    <template #input>
        <div></div>
    </template>

    <template #options>
        <div class="input-group grid grid-cols-12 gap-2">
            <label class="col-span-12 md:col-span-2">Plain text</label>
            <input class="col-span-12 md:col-span-10 text-field" name="text" type="text" v-model="store.data[slug].options.text" :placeholder="_.split(store.feature.sample_placeholder, '|')[0]">
        </div>
        <div class="input-group grid grid-cols-12 gap-2 mt-4">
            <label class="col-span-12 md:col-span-2">Hash</label>
            <input class="col-span-12 md:col-span-10 text-field" name="hash" type="text" v-model="store.data[slug].options.hash" :placeholder="_.split(store.feature.sample_placeholder, '|')[1]">
        </div>
    </template>

        <template #output>
            <div :class="`text-xl alert alert--${store.data[slug].output.includes('SUCCESS') ? 'success' : 'danger'}`">
                {{ store.data[slug].output }}
            </div>
        </template>
    </LayoutTextConverter>
</template>

<script setup lang="ts">
import LayoutTextConverter from "&/LayoutTextConverter.vue"

import { useRoute } from "vue-router";
import { useStore } from '%/stores'
import _ from 'lodash'

const route = useRoute()
const store = useStore()
const slug = route.path.replace('/', '')
</script>
<script setup>
</script>
