<template>
    <LayoutCodeConverter :slug="slug" input-lang="xml" output-lang="yaml">
    </LayoutCodeConverter>
</template>

<script setup lang="ts">
import LayoutCodeConverter from "&/LayoutCodeConverter.vue"
import { useRoute } from "vue-router";

const route = useRoute()
const slug = route.path.replace('/', '')
</script>
