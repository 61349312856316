<template>
  <input
    v-model="query"
    autofocus
    type="search"
    placeholder="Search tools"
    class="search w-full p-4 bg-white rounded-xl border-2 border-solid border-gray-500"
  />

  <div class="relative search-dropdown">
    <div
      v-show="showDropdown"
      class="border border-solid border-gray-300 shadow-xl absolute top-0 w-full z-50 overflow-y-scroll max-h-[480px]"
    >
      <router-link
        v-for="feature in searchedFeatures"
        :key="feature.slug"
        :to="{ path: `/${feature.slug}` }"
        class="h-14 px-2 py-1 flex items-center bg-white hover:bg-green-100 transition last-of-type:border-0 border-b border-solid border-gray-300"
      >
        <AppFeatureIcon :slug="feature.slug" class="h-10 mr-2" />
        <span v-safe-html="highlightMatch(feature.name)"></span>
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from '%/stores'
import AppFeatureIcon from '&/AppFeatureIcon.vue'

const store = useStore()

// Set up reactive references
const query = ref('')
const showDropdown = ref(false)
const features = Object.values(store.categories)
  .map((category) => category.features)
  .flat()

// Set up computed property that returns an array of features that includes the query in their names.
const searchedFeatures = computed(() => {
  return features.filter((item) => {
    return item.name.toLowerCase().indexOf(query.value.toLowerCase()) != -1
  })
})

// Highlight matching text within the feature name.
// If a match is found, the matching substring will be wrapped in a <strong> tag.
const highlightMatch = (featureName) => {
  const queryValue = query.value.toLowerCase()
  const featureNameLower = featureName.toLowerCase()

  if (!queryValue) {
    return featureName
  }

  const start = featureNameLower.indexOf(queryValue)

  if (start === -1) {
    return featureName
  }

  const end = start + queryValue.length

  return `${featureName.substring(0, start)}<strong>${featureName.substring(
    start,
    end
  )}</strong>${featureName.substring(end)}`
}

// This function will be called whenever a click event occurs.
// If the clicked element is outside the dropdown, it hides the dropdown.
const onClickOutside = (event) => {
  if (!event.target.closest('.search') && !event.target.closest('.search-dropdown')) {
    showDropdown.value = false
  }
}

// Watch the query.
// When the query changes, it displays the dropdown if the new query is not an empty string.
// Otherwise, it hides the dropdown.
watch(query, (newQuery) => {
  if (newQuery && newQuery !== ' ') {
    showDropdown.value = true
  } else {
    showDropdown.value = false
  }
})

// When the component is mounted, it sets up an event listener that calls onClickOutside whenever a click event occurs.
onMounted(() => {
  document.addEventListener('click', onClickOutside)
})

// Before the component is unmounted, it removes the event listener to prevent memory leaks.
onBeforeUnmount(() => {
  document.removeEventListener('click', onClickOutside)
})
</script>

<style scoped lang="sass">
.search-dropdown
    :deep(strong)
        color: green
</style>
