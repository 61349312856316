import { onMounted } from "vue";
import { Dropdown } from "flowbite";

import {
    initAccordions,
    initCarousels,
    initCollapses,
    initDials,
    initDismisses,
    initDrawers,
    initDropdowns,
    initModals,
    initPopovers,
    initTabs,
    initTooltips
} from 'flowbite';

export function useFlowbite(components) {
    onMounted(() => {
        components.forEach(component => {
            switch (component) {
                case 'accordions': initAccordions(); break;
                case 'carousels': initCarousels(); break;
                case 'collapses': initCollapses(); break;
                case 'dropdowns': initDropdowns(); break;
                case 'dials': initDials(); break;
                case 'dismisses': initDismisses(); break;
                case 'drawers': initDrawers(); break;
                case 'modals': initModals(); break;
                case 'popovers': initPopovers(); break;
                case 'tabs': initTabs(); break;
                case 'tooltips': initTooltips(); break;
                default:
                    console.error(`Component "${component}" is not recognized or not supported.`);
            }
        });
    });
}

export function closeDropdown(targetEl, triggerEl) {
    const $targetEl = document.getElementById(targetEl)
    const $triggerEl = document.getElementById(triggerEl);
    if ($triggerEl && $triggerEl) {
        const dropdown = new Dropdown($targetEl, $triggerEl)
        dropdown.hide()
    }
}
