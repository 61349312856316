<template>
  <div class="bg-stone-100 p-4">
    <AppBreadcrumbs :breadcrumbs="breadcrumbs" />

    <div v-if="article" class="mx-auto max-w-screen-lg">
      <AppMarkdown :content="article.content" class="p-8 shadow-md" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useStore } from '%/stores'
import AppMarkdown from '&/AppMarkdown.vue'
import { useHead } from '@vueuse/head'

const store = useStore()
const article = ref(null)
const breadcrumbs = ref([])

onMounted(async () => {
  article.value = await store.fetchArticle('about')
})

useHead({
  title: 'About toolpro.dev'
})

breadcrumbs.value = [{ path: null, name: `About` }]
</script>
