<template>
  <div class="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-2 items-center justify-center w-full">
    <label class="text-gray-800 font-bold min-w-max">{{ label }}</label>
    <div class="flex space-x-2 w-full">
      <input
        :value="modelValue"
        :type="type"
        class="text-field"
        :placeholder="placeholder"
        @input="updateValue"
        @keyup.enter="$emit('enter')"
        @focus="$emit('focus', $event)"
      />
      <slot> </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    modelValue: string
    label?: string
    placeholder?: string
    type?: string
  }>(),
  {
    label: '',
    placeholder: 'Input',
    type: 'text'
  }
)

const emit = defineEmits(['enter', 'focus', 'update:modelValue'])

const updateValue = (event: Event) => {
  emit('update:modelValue', (event.target as HTMLInputElement).value)
}
</script>
