<template>
    <LayoutTextConverter :slug="slug" class="cm-to-inches">
        <template #input>
          <div class="text-2xl flex space-x-4 items-center mx-auto max-w-[300px]">
            <input name="cm" type="number" v-model="store.data[slug].input" class="text-field w-[200px] text-right" min="0" placeholder="2.54" @focus="selectAll($event)" @keyup=toInches()>
            <div class="">CM</div>
          </div>
        </template>

        <template #submit>
          <div class="text-center text-lg">is equal to</div>
        </template>

        <template #output>
          <div class="text-2xl flex space-x-4 items-center mx-auto max-w-[300px]">
            <input name="inches" type="number" v-model="store.data[slug].output" class="text-field w-[200px] text-right" min="0" placeholder="1" @focus="selectAll($event)" @keyup="toCm">
            <div class="">Inches</div>
          </div>
        </template>
    </LayoutTextConverter>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'
import LayoutTextConverter from "&/LayoutTextConverter.vue"
const store = useStore()

import { useRoute } from "vue-router";
const route = useRoute()
const slug = route.path.replace('/', '')

const toInches = function() {
  store.data[slug].output = Number((store.data[slug].input * 0.393701).toFixed(2))
}

const toCm = function() {
  store.data[slug].input = Number((store.data[slug].output / 0.393701).toFixed(2))
}

function selectAll(event) {
  event.target.select();
}
</script>
