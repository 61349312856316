<template>
  <div :id="id"></div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    id?: string
  }>(),
  {
    id: 'bmac'
  }
)

import { onMounted } from 'vue'
import { useStore } from '%/stores'
import postscribe from 'postscribe'

const store = useStore()
onMounted(() => {
  postscribe(`#${props.id}`, store.systemConfigs.buymeacoffee_script)
})
</script>
