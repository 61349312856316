<template>
    <LayoutTextConverter :slug="slug" output-height="300">
        <template #input>
            <div></div>
        </template>
        <template #options>
            <div class="flex space-x-4 justify-center">
                <div class="input-group min-w-[240px]">
                    <label>Quantity (max 1000)</label>
                    <input type="number" v-model="store.data[slug].options.quantity" min="1" max="1000" class="text-field">
                </div>
            </div>
        </template>
    </LayoutTextConverter>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'
import LayoutTextConverter from "&/LayoutTextConverter.vue"

const store = useStore()
import { useRoute } from "vue-router";
const route = useRoute()
const slug = route.path.replace('/', '')
</script>
