<template>
  <div class="flex flex-col shadow-lg w-full" :style="`height: ${height}px`">
    <section class="min-h-[50px] p-2 bg-gradient-to-bl from-teal-200 to-green-300 flex justify-between items-center">
      <div class="font-bold">
        <span v-if="forOutput">Output {{ actionText }}</span>
        <span v-else>Input</span>
      </div>

      <div class="flex flex-wrap space-x-2">
        <input ref="file" class="hidden" type="file" @change="selectFile" />
        <button v-if="!forOutput" class="action-button text-blue-700" @click="$emit('getSample')">
          <i class="fa-solid fa-file-code"></i>
          <span class="font-bold">Sample</span>
        </button>

        <button v-if="!forOutput" class="action-button" @click="selectFileButtonClick">
          <i class="fa-solid fa-upload"></i>
          <span>From file</span>
        </button>

        <button v-if="forOutput" class="action-button" @click="download">
          <i class="fa-solid fa-file-arrow-down"></i>
          <span>Download</span>
        </button>

        <button class="action-button" @click="clear">
          <i class="fa-solid fa-trash-can"></i>
          <span>Clear</span>
        </button>

        <button class="action-button font-normal bg-gradient-to-b from-teal-200 via-blue-200 to-blue-200" @click="copy">
          <i class="fa-solid fa-paste"></i>
          <span>Copy</span>
        </button>
      </div>
    </section>

    <v-ace-editor
      :id="editorId"
      v-model:value="content"
      :wrap="true"
      :options="options"
      :lang="lang"
      theme="chrome"
      :placeholder="placeholder"
      class="h-full border border-solid border-gray-300"
      :class="fontClass"
      @input="updateValue"
      @init="editorInit"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { notify } from '@kyvg/vue3-notification'

// ACE Editor
import { Ace } from 'ace-builds'
import { VAceEditor } from 'vue3-ace-editor'
import 'ace-builds/src-min-noconflict/theme-chrome'
import 'ace-builds/src-min-noconflict/mode-text'
import 'ace-builds/src-min-noconflict/mode-json'
import 'ace-builds/src-min-noconflict/mode-xml'
import 'ace-builds/src-min-noconflict/mode-yaml'
import 'ace-builds/src-min-noconflict/mode-css'
import 'ace-builds/src-min-noconflict/mode-scss'
import 'ace-builds/src-min-noconflict/mode-sass'
import 'ace-builds/src-min-noconflict/mode-less'
import 'ace-builds/src-min-noconflict/mode-javascript'
import 'ace-builds/src-min-noconflict/mode-typescript'
import 'ace-builds/src-min-noconflict/mode-markdown'
import 'ace-builds/src-min-noconflict/mode-html'
import 'ace-builds/src-min-noconflict/mode-php'
import 'ace-builds/src-min-noconflict/mode-ruby'
import 'ace-builds/src-min-noconflict/mode-python'
import 'ace-builds/src-min-noconflict/mode-java'
import 'ace-builds/src-min-noconflict/mode-vbscript'
import 'ace-builds/src-min-noconflict/mode-cobol'
import 'ace-builds/src-min-noconflict/mode-csharp'
import 'ace-builds/src-min-noconflict/mode-golang'
import 'ace-builds/src-min-noconflict/mode-pascal'

const options = {
  // Available options: https://github.com/ajaxorg/ace/wiki/Configuring-Ace
  readOnly: false,
  useWorker: true // Enable syntax checking
}

const editor = ref<Ace.Editor>()
const editorInit = (e: Ace.Editor) => {
  editor.value = e
}

// Enable syntax checking
import ace from 'ace-builds'
import workerJsonUrl from 'ace-builds/src-min-noconflict/worker-json?url'
import workerXmlUrl from 'ace-builds/src-min-noconflict/worker-xml?url'
import workerYamlUrl from 'ace-builds/src-min-noconflict/worker-yaml?url'
import workerCssUrl from 'ace-builds/src-min-noconflict/worker-css?url'
import workerJsUrl from 'ace-builds/src-min-noconflict/worker-javascript?url'
import workerHtmlUrl from 'ace-builds/src-min-noconflict/worker-html?url'
import workerPhpUrl from 'ace-builds/src-min-noconflict/worker-php?url'

ace.config.setModuleUrl('ace/mode/json_worker', workerJsonUrl)
ace.config.setModuleUrl('ace/mode/xml_worker', workerXmlUrl)
ace.config.setModuleUrl('ace/mode/yaml_worker', workerYamlUrl)
ace.config.setModuleUrl('ace/mode/css_worker', workerCssUrl)
ace.config.setModuleUrl('ace/mode/javascript_worker', workerJsUrl)
ace.config.setModuleUrl('ace/mode/html_worker', workerHtmlUrl)
ace.config.setModuleUrl('ace/mode/php_worker', workerPhpUrl)
/////////////////////////

const props = withDefaults(
  defineProps<{
    modelValue: string
    placeholder?: string
    forOutput?: boolean
    actionText?: string
    lang?: string
    fontClass?: string
    editorId?: string
    height?: string
  }>(),
  {
    placeholder: '',
    actionText: 'Convert',
    forOutput: false,
    lang: 'text',
    fontClass: 'ace-text-font',
    editorId: 'ace-input',
    height: '640'
  }
)

const emit = defineEmits(['getSample', 'update:modelValue'])

const file = ref(null)

const content = ref('')

const selectFileButtonClick = function () {
  file.value.click()
}
const updateValue = () => {
  emit('update:modelValue', content.value)
}

const selectFile = (e: Event) => {
  const reader = new FileReader()
  reader.onload = function () {
    emit('update:modelValue', reader.result)
  }
  reader.readAsText(e.target.files[0])
}

const clear = function () {
  emit('update:modelValue', '')
}

const copy = function () {
  navigator.clipboard.writeText(props.modelValue)
  notify({
    type: 'success',
    text: 'Copied to Clipboard!'
  })
}

const download = () => {
  const text = ace.edit('ace-output').getValue()
  const filename = `toolpro.dev.${props.lang === 'text' ? 'txt' : props.lang}`
  const blob = new Blob([text], { type: 'text/plain;charset=utf-8' })
  const url = URL.createObjectURL(blob)
  const downloadLink = document.createElement('a')
  downloadLink.href = url
  downloadLink.download = filename
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

watch(
  () => props.modelValue,
  (newValue) => {
    content.value = newValue
  },
  { immediate: true } // call the watcher immediately during setup, not waiting for the value to change
)
</script>

<style scoped lang="sass">
.action-button
    min-width: 50px
    border-radius: 5px
    border: 1px solid gray
    padding: 2px 4px
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px
    &:hover
        color: white
        background: #16a34a
    span
      @include respond(SP)
        display: none
</style>
