<template>
  <notifications position="bottom left" classes="notification" />
  <TheHeader />
  <TheContainer />
  <TheFooter />
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useStore } from '%/stores'
import TheHeader from '&/TheHeader.vue'
import TheFooter from '&/TheFooter.vue'
import TheContainer from '&/TheContainer.vue'

const store = useStore()
store.dataLoading = true

onMounted(async () => {
  await store.fetchSystemConfigs()
  await store.fetchCategories()
  store.dataLoading = false
})
</script>
