<template>
  <LayoutCodeConverter :slug="slug" input-lang="less" output-lang="css">
      <template #options>
        <OptionIndent :slug="slug"/>
      </template>
  </LayoutCodeConverter>
</template>

<script setup lang="ts">
import OptionIndent from "&/OptionIndent.vue";

import { useFeatureSetup } from '&/composables/featureSetup'
const { route, store, slug, LayoutCodeConverter } = useFeatureSetup()
</script>
