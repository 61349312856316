<template>
    <LayoutCodeConverter :slug="slug" :input-lang="resultLang" input-height="480">
      <template #output>
        <div class="w-full flex flex-col">
          <AppTextInput
            v-model="resultCode"
            :for-output="true"
            editor-id="ace-output"
            font-class="ace-code-font"
            height="480"
            :lang="resultLang"
            :action-text="store.feature.action_text"
          />

          <div v-if=resultDesc class="mt-4 alert">
            <div class="font-bold">{{ resultLang }} detected.</div>
            <div v-safe-html="resultDesc"></div>
          </div>
        </div>
      </template>
    </LayoutCodeConverter>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { useStore } from '%/stores'
import { notify } from '@kyvg/vue3-notification'
import LayoutCodeConverter from "&/LayoutCodeConverter.vue"
import AppTextInput from '&/AppTextInput.vue'

const store = useStore()

const parseError = ref(false)

const resultLang = computed(() => {
  try {
    if (store.data[slug].output !== '') {
      return JSON.parse(store.data[slug].output).lang.toLowerCase()
    } else {
      return 'text'
    }
  } catch {
    parseError.value = true
  }
})

const resultCode = computed(() => {
  try {
    if (store.data[slug].output !== '') {
      return JSON.parse(store.data[slug].output).code
    } else {
      return ''
    }
  } catch {
    parseError.value = true
  }
})

const resultDesc = computed(() => {
  try {
    if (store.data[slug].output !== '') {
      return JSON.parse(store.data[slug].output).desc.replace(/\n/g, '<br/>')
    } else {
      return ''
    }
  } catch {
    parseError.value = true
  }
})

watch(() => parseError.value, (newValue) => {
  if (newValue) {
    notify({
      type: 'error',
      title: 'An error occurred',
      text: 'Please try again or input a shorter code snippet.'
    })
  }
})

import { useRoute } from "vue-router";
const route = useRoute()
const slug = route.path.replace('/', '')
</script>
