<template>
  <div class="input-group options__indent">
    <label>Indent</label>
    <select v-model="store.data[slug].options.indent" class="text-field" @change="store.execute(slug)">
      <option v-for="item in items" :key="item" :value="item">{{ item }} spaces</option>
    </select>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  slug: string
}>()
import { useStore } from '%/stores'

const store = useStore()
const items = [2, 4, 8]
</script>
