<template>
  <AppAdSense
    ad-class="adsense adsense--footer"
    ad-style="display:block"
    ad-client="ca-pub-9118328092882671"
    ad-slot="5517066230"
    ad-format="auto"
    ad-responsive="true"
  />
</template>

<style lang="sass" scoped>
.adsense--footer
  min-height: 50px
</style>
