import { defineStore } from "pinia";
import apiCall from "%/plugins/axios";
import { notify } from "@kyvg/vue3-notification"
import _ from "lodash"

export const useStore = defineStore('', {
    state: () => ({
        systemConfigs: {},
        dataLoading: false,
        dataSending: false,
        ready: false,
        data: {
            'json-formatter': {
                input: '',
                output: '',
                options: {
                    indent: 2,
                }
            },
            'json-minifier': {
                input: '',
                output: ''
            },
            'json-to-xml': {
                input: '',
                output: '',
                options: {
                    indent: 4,
                    pretty: true,
                    compact: true,
                    declaration: true,
                    key_converter: 'none'
                }
            },
            'json-to-yaml': {
                input: '',
                output: ''
            },

            'xml-formatter': {
                input: '',
                output: '',
                options: {
                    indent: 4,
                }
            },
            'xml-minifier': {
                input: '',
                output: ''
            },
            'xml-to-json': {
                input: '',
                output: '',
                options: {
                    indent: 2,
                    key_converter: 'none',
                    pretty: true,
                    quote_numbers: false
                }
            },
            'xml-to-yaml': {
                input: '',
                output: ''
            },

            'yaml-beautifier': {
                input: '',
                output: ''
            },
            'yaml-to-json': {
                input: '',
                output: '',
                options: {
                    indent: 2,
                    key_converter: 'none',
                    pretty: true,
                    quote_numbers: false
                }
            },
            'yaml-to-xml': {
                input: '',
                output: '',
                options: {
                    indent: 4,
                    pretty: true,
                    compact: true,
                    declaration: true,
                    key_converter: 'none'
                }
            },

            'css-beautifier': {
                input: '',
                output: '',
                options: {
                    indent: 2,
                    open_brace_end_of_line: true,
                    auto_semicolon: true
                }
            },
            'css-minifier': {
                input: '',
                output: '',
                options: {
                    length: 5000
                }
            },
            'css-to-scss': {
                input: '',
                output: '',
                options: {
                    indent: 2,
                }
            },
            'css-to-sass': {
                input: '',
                output: '',
                options: {
                    indent: 2,
                }
            },
            'css-to-less': {
                input: '',
                output: '',
                options: {
                    indent: 2,
                }
            },
            'scss-to-css': {
                input: '',
                output: '',
                options: {
                    indent: 2,
                    style: 'expanded'
                }
            },
            'scss-to-sass': {
                input: '',
                output: '',
                options: {
                    indent: 2,
                }
            },
            'sass-to-css': {
                input: '',
                output: '',
                options: {
                    indent: 2,
                    style: 'expanded'
                }
            },
            'sass-to-scss': {
                input: '',
                output: '',
                options: {
                    indent: 2,
                }
            },
            'less-to-css': {
                input: '',
                output: '',
                options: {
                    indent: 2,
                }
            },

            'js-beautifier': {
                input: '',
                output: '',
                options: {
                    indent: 4,
                    remove_comments: false,
                    remove_unreachable_code: false,
                    remove_debugger: false
                }
            },
            'js-minifier': {
                input: '',
                output: '',
                options: {
                    length: 5000
                }
            },

            'base64-encode': {
                input: '',
                output: ''
            },
            'base64-decode': {
                input: '',
                output: ''
            },
            'base64-to-json': {
                input: '',
                output: ''
            },
            'json-to-base64': {
                input: '',
                output: ''
            },
            'base64-to-image': {
                input: '',
            },

            'bcrypt-password-generator': {
                input: '',
                output: '',
                options: {
                    rounds: '10'
                }
            },
            'bcrypt-hash-verifier': {
                input: '',
                output: '',
                options: {
                    text: '',
                    hash: ''
                }
            },
            'sha-512-hash-generator': {
                input: '',
                output: '',
            },

            'text-replacer': {
                input: '',
                output: '',
                options: {
                    replace_from: '',
                    replace_to: ''
                }
            },
            'reverse-string': {
                input: '',
                output: '',
                options: {
                    reverse_by: 'letter'
                }
            },
            'case-converter': {
                input: '',
                output: '',
                options: {
                    case: 'upcase'
                }
            },
            'remove-accents': {
                input: '',
                output: ''
            },
            'slug-converter': {
                input: '',
                output: ''
            },

            'decimal-to-binary': {
                input: '',
                output: ''
            },

            'my-ip-address': {
                input: '',
                output: ''
            },
            'whois-ip-lookup': {
                input: '',
                output: ''
            },
            'dns-lookup': {
                input: '',
                output: ''
            },

            'cm-to-inches': {
                input: '',
                output: ''
            },

            'random-uuid-generator': {
                output: '',
                options: {
                    quantity: 10
                }
            },
            'random-address-generator': {
                output: '',
                options: {
                    country: 'US'
                }
            },

            'sample-code-generator': {
                output: '',
                options: {
                    language: 'python',
                }
            },
            'code-refactor': {
                input: '',
                output: '',
                options: {
                    language: 'English'
                }
            },

            'online-editors': {
                theme: 'dark'
            },
            'online-markdown-editor': {
                input: ''
            },

            'programming-editors': {
                theme: 'dark'
            }
        },
        categories: [],
        feature: {},
    }),

    actions: {
        fetchSystemConfigs() {
            return new Promise(resolve => {
                apiCall.get(`/system_configs`).then((res) => {
                    this.systemConfigs = res.data
                    resolve()
                })
            })
        },
        fetchCategories() {
            return new Promise(resolve => {
                apiCall.get(`/categories`).then((res) => {
                    this.categories = res.data
                    resolve()
                })
            })
        },
        fetchFeature(slug) {
            return new Promise(resolve => {
                apiCall.get(`/features/${slug}`).then((res) => {
                    this.feature = res.data
                    resolve()
                })
            })
        },
        getSample(slug) {
            this.data[slug].input = this.feature.sample_input
        },
        executeSample(slug) {
            this.getSample(slug)
            if (!_.includes(['base64-to-image'], slug)) {
                this.execute(slug)
            }
        },
        execute(slug) {
            this.data[slug].output = ''
            return new Promise((resolve, reject) => {
                this.dataSending = true
                apiCall
                    .post(`/features/${slug}`, {
                        input: this.data[slug].input,
                        options: this.data[slug].options
                    })
                    .then((res) => {
                        this.data[slug].output = res.data.result
                        resolve()
                    })
                    .catch((err) => {
                        notify({
                            type: 'error',
                            title: 'Error',
                            text: err.response.data.error
                        })
                        reject()
                    })
                    .finally(() => this.dataSending = false)
            })
        },
        fetchArticle(slug) {
            return new Promise(resolve => {
                this.dataSending = true
                apiCall.get(`/articles/${slug}`)
                    .then((res) => {
                        resolve(res.data)
                    })
                    .catch((err) => {
                        notify({
                            type: 'error',
                            title: 'Error',
                            text: err.response.data.error
                        })
                        reject()
                    })
                    .finally(() => this.dataSending = false)
            })
        },
    },

    getters: {
        getCategoryBySlug: (state) => (slug) => {
            return state.categories.find(category => category.slug === slug);
        }
    }
})
