<template>
  <AppAdSense
    ad-class="adsense adsense--top"
    ad-style="display:block"
    ad-client="ca-pub-9118328092882671"
    ad-slot="2261780729"
    ad-responsive="true"
  />
</template>

<style lang="sass" scoped>
.adsense--top
  width: 320px
  height: 150px
  margin: auto
@media(min-width: 500px)
  .adsense--top
    width: 512px
    height: 150px
@media(min-width: 800px)
  .adsense--top
    width: 1152px
    height: 150px
</style>
