import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from '%/stores'
import { closeDropdown } from "&/composables/useFlowbite";
import LayoutCodeConverter from "&/LayoutCodeConverter.vue";
import LayoutTextConverter from "&/LayoutTextConverter.vue";
import LayoutProgrammingEditor from "&/LayoutProgrammingEditor.vue";
import LayoutPlain from "&/LayoutPlain.vue";

export function useFeatureSetup() {
    const route = useRoute()
    const store = useStore()
    const slug = route.path.replace('/', '')

    onMounted(() => {
        closeDropdown('dropdownCSS', 'dropdownCSSLink')
        closeDropdown('dropdownEditors', 'dropdownEditorsLink')
    })

    return {
        route,
        store,
        slug,
        LayoutCodeConverter,
        LayoutTextConverter,
        LayoutProgrammingEditor,
        LayoutPlain,
    }
}
