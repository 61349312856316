<template>
    <LayoutTextConverter :slug="slug"/>
</template>

<script setup lang="ts">
import LayoutTextConverter from "&/LayoutTextConverter.vue"

import { useRoute } from "vue-router";
const route = useRoute()
const slug = route.path.replace('/', '')
</script>
